import React, { useState, useEffect } from "react";
import Tabletop from 'tabletop';
import Papa from "papaparse";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import './App.scss';
import Lineup from './components/Lineup.jsx';
import Tickets from './components/Tickets.jsx';
import Events from './components/Events.jsx';
import Map from './components/Map.jsx';
import OpenCall from './components/OpenCall.jsx';

function App() {
  const [pathname, setPathname] = useState(window.location.pathname);

  useEffect(() => {
    setPathname(window.location.pathname);
  })

  return (
    <Router>
      <div className="App">
        <div className="logoSocial">
          <Link to="/" className="logo" onClick={()=>setPathname('/')}>Art-Space-Hopping-Moscow&#8209;>19/20-12-2020</Link>
          <div className="social">
            <a target="_blank" href="https://www.instagram.com/a_s_h_mscw/?fbclid=IwAR1Cr9bsI_J9hXTTtkVGhX8dQ8g0To80f5NLzWr-ctz3mWHTTTbSlhpKdrY">instagram</a>
            <a target="_blank" href="https://www.facebook.com/events/1012591432585945">facebook</a>
            <a target="_blank" href="https://t.me/artspacehopping">telegram</a>
          </div>
        </div>

        <Switch>
          <Route path="/open_call">
            <OpenCall/>
            <Lineup/>
          </Route>

          <Route path="/events">
            <Events/>
          </Route>

          <Route path="/tickets">
            <Tickets/>
          </Route>

          <Route path="/">
            <Lineup/>
          </Route>

        </Switch>

        {pathname == "/" ? (
          <div className="menu">
            <div><Link to="/events" onClick={()=>setPathname('/events')}>программа</Link></div>
            <div></div>
            <div><a href="https://www.google.com/maps/d/u/2/viewer?mid=1oUKt5x9OwP79dVr0sVQKSP5EVFH3W4uk" target="_blank" className="map">карта</a></div>
          </div>
        ) : ('')}

        {pathname == "/events" ? (
          <div className="menu">
            <div><Link to="/" onClick={()=>setPathname('/')}>участники</Link></div>
            <div><a href="https://www.google.com/maps/d/u/2/viewer?mid=1oUKt5x9OwP79dVr0sVQKSP5EVFH3W4uk" target="_blank" className="map">карта</a></div>
          </div>
        ) : ('')}

        {pathname == "/tickets" ? (
          <div className="menu">
            <div><Link to="/" onClick={()=>setPathname('/')}>участники</Link></div>
            <div><Link to="/events" onClick={()=>setPathname('/events')}>программа</Link></div>
            <div><a href="https://www.google.com/maps/d/u/2/viewer?mid=1oUKt5x9OwP79dVr0sVQKSP5EVFH3W4uk" target="_blank" className="map">карта</a></div>
          </div>
        ) : ('')}
      </div>
    </Router>
  );
}

export default App;

// сб и вс две колонки / регистрация / описание / раскрывашка
