import React, { useState, useEffect, useRef } from "react";
import Papa from "papaparse";

export default function Events() {
  useEffect(() => {
    window.scrollTo({
      top: 0
    })
  }, [])

  return(
    <div className="eventsPage">
      <div>
        <p>
          19-12-2020, сб<br/>
          <br/>
          11:00&ndash;16:00 Мастер-класс &laquo;Лино-открытка&raquo;<br/>
          Арт-студия Марии Зайцевой &laquo;Белый кролик&raquo; (17)<br/>
          <br/>
          12:00&ndash;17:00 Печать открыток на&nbsp;офортном станке<br/>
          Мастерская AMMA (21)<br/>
          <br/>
          15:00&ndash;17:00 Экспериментальная фотопечать (мордансаж)<br/>
          Лаборатория &laquo;ФотоСецессион&raquo; (22) <br/>
          <br/>
          19:00 Влад Незабудкин, перформанс &laquo;Сеанс быстрых портретов&raquo; <br/>
          Je&nbsp;suis ИСИ (45)<br/>
          <br/>
          20:00&ndash;22:00 Мастер-класс по&nbsp;цианотипии<br/>
          Лаборатория &laquo;ФотоСецессион&raquo; (22)<br/>
          <br/>
          19:12&ndash;13:00 Открытый урок &laquo;Печатная графика&raquo; студентов магистратуры Школы Дизайна ВШЭ<br/>
          ИМПРИНТ (56)<br/>
          <br/>
          20:00&ndash;22:00 Музыкальная импровизация для дуэта в&nbsp;двух частях при участии Петра Юдкина (ударные и&nbsp;духовые)<br/>
          Qomnata (51)<br/>
          <br/>
          21:00 Сходимся в&nbsp;одной точке<br/>
          <a href="https://www.instagram.com/september.moscow/" targer="_blank">Бар &laquo;Сентябрь&raquo;</a><br/>
          <br/>
          <br/>
          <br/>

          20-12-2020, вс<br/>
          <br/>
          14:00&ndash;16:00 Мастер-класс по&nbsp;каллитипии<br/>
          18:00&ndash;20:00 Мастер-класс по&nbsp;гумойлу<br/>
          Лаборатория &laquo;ФотоСецессион&raquo; (22)<br/>
          <br/>
          16:00&ndash;20:00 Печать открыток на&nbsp;офортном станке<br/>
          Мастерская AMMA (21)<br/>
          <br/>
          20:00&ndash;21:30 Женя Стерлягова, лекция &laquo;Прямая фотография 100 лет тому&nbsp;назад!&raquo;<br/>
          Je&nbsp;suis ИСИ (45)<br/>
          <br/>
          15:00&ndash;20:00 DoBukvy рисует ваши портреты, в&nbsp;экспрессивно-футуристичном&nbsp;стиле<br/>
          21:00 DoBukvy несёт искусство в&nbsp;массы. Лотерея<br/>
          DoBukvy (53)<br/>
          <br/>
          13:00&ndash;16:00 Высокая печать<br/>
          16:00&ndash;18:00 Глубокая печать: сухая игла<br/>
          ИМПРИНТ (56)<br/>
          <br/>
          17:00&ndash;19:00 Мастер класс по&nbsp;коллаграфии Дарьи Климась<br/>
          19:30&ndash;20:30 Выступление Гитарного Ансамбля Москвы<br/>
          Простая школа (19)<br/>
          <br/>
          20:00&ndash;22:00 Музыкальная импровизация для дуэта в&nbsp;двух частях при участии Петра Юдкина (электроника)<br/>
          Qomnata (51)<br/>
          <br/>
          Закрываем A-S-H-M&nbsp;в особняке <a href="https://www.instagram.com/wip.msk/" target="_blank">WIP</a> вместе с&nbsp;ZINEISLAND (30):<br/>
          18:00&ndash;20:00 Ivan Pustovalov (второй этаж)<br/>
          20:00&ndash;21:00 Видео программа от&nbsp;telegram-канала <a href="https://t.me/fckndfght" target="_blank"> FCK&amp;FGHT</a> (второй этаж)<br/>
          20:00&ndash;21:00&nbsp;KP Transmission (первый этаж)<br/>
          21:00&ndash;22:00 vietnamguerra (второй этаж)<br/>
          21:00&ndash;23:00 Karolina Bnv (первый этаж)<br/>
        </p>
        <div className="logos"><div className="logo1"/><div className="logo2"/><div className="logo3"/></div>
      </div>
      <div className="poster"/>
    </div>
  )
}
