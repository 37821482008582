import React, { useState, useEffect, useRef } from "react";
import Papa from "papaparse";
import Row from './Row.jsx';

export default function Lineup(props) {
  const [ogData, setOgData] = useState([]);
  const [data, setData] = useState([]);
  const [appliedFormatFilter, setAppliedFormatFilter] = useState('все');
  const [appliedPlatformFilter, setAppliedPlatformFilter] = useState('все');

  const formatSelectRef = useRef(null);
  const platformSelectRef = useRef(null);

  useEffect(() => {
    Papa.parse('https://docs.google.com/spreadsheets/d/e/2PACX-1vTI6LGhDX495_4JlqZgxE_YLOR1GpAybqpfFPe6NzsgKn6oKFopISfXpccHTM3IwIZ8r-RmdPzuclI4/pub?output=csv', {
      download: true,
      header: true,
      complete: function(results) {
        var googleData = results.data
        setOgData(googleData);
        setData(googleData);
      }
    })
  }, [])

  function filterByFormat () {
    console.log('filterByFormat');
    console.log(formatSelectRef.current.value);
    console.log(appliedPlatformFilter);

    let format = formatSelectRef.current.value;
    setAppliedFormatFilter(format);

    filterData(format, appliedPlatformFilter);
  }

  function filterByPlatform () {
    console.log('filterByPlatform');
    console.log(platformSelectRef.current.value);
    console.log(appliedFormatFilter);

    let platform = platformSelectRef.current.value;
    setAppliedPlatformFilter(platform);

    filterData(appliedFormatFilter, platform);
  }

  function filterData(format, platform) {
    let filteredData = [];
    console.log(format, platform);

    ogData.map(item => {
      let itemFormats = []
      if (item.formats) {
        itemFormats = item.formats.split(", ")
      }
      console.log(itemFormats);

      if (format == "все" && platform == "все") {
        filteredData.push(item);
      } else if (format != "все" && platform == "все") {
        if (itemFormats.includes(format)) {
          filteredData.push(item);
        }
      } else if (format.toLowerCase() == "все" && platform != "все") {
        if (item.platform.toLowerCase() && platform.toLowerCase() == item.platform.toLowerCase()) {
          filteredData.push(item);
        }
      } else if (format != "все" && platform != "все") {
        if (item.platform && itemFormats.includes(format) && platform.toLowerCase() == item.platform.toLowerCase()) {
          filteredData.push(item);
        }
      }
    })

    setData(filteredData);
  }

  let formatFilters = [];
  ogData.map(item => {
    let itemFormats = []
    if (item.formats) {
      itemFormats = item.formats.split(", ")
    }
    itemFormats.map(format => {
      if (formatFilters.includes(format)) {
      } else {
        formatFilters.push(format)
      }
    })
  })

  let platformFilters = [];
  ogData.map(item => {
    if (platformFilters.includes(item.platform)) {
    } else {
      platformFilters.push(item.platform)
    }
  })

  return (
    <>
      <div className="head">
        <div className="mainDescription">
        Art-Space-Hopping&nbsp;&mdash; это прогулка по&nbsp;мастерским художников, студиям, квартирам, дружественным площадкам, которые откроют свои двери для камерных визитов на&nbsp;один предновогодний уик-энд. Пандемия поменяла правила игры&nbsp;&mdash; все на&nbsp;одной карте, а&nbsp;не&nbsp;в&nbsp;одно месте. Это инициатива при содействии Музея Москвы, которая стремится поддержать художников и&nbsp;локальные художественные сообщества. Зрители составляют маршрут, переходя из&nbsp;мастерских в&nbsp;студии, заходят в&nbsp;гости к&nbsp;художникам, перемещаются в&nbsp;дружественные пространства, бары и&nbsp;кафе, в&nbsp;поиске подарков и&nbsp;хорошего настроения. Если вы&nbsp;остаетесь дома, работы художников можно посмотреть на&nbsp;сайтах и&nbsp;приобрести в&nbsp;онлайн-магазинах. Так каждый создает свою карту по&nbsp;неочевидным художественным пространствам Москвы.<br/>Поддержите инициативу, купив билет за&nbsp;200 рублей на&nbsp;все площадки на&nbsp;оба&nbsp;дня&nbsp;события.
        </div>

        <div className="filters">
          <p>
            <select onChange={filterByFormat} ref={formatSelectRef}>
              <option selected value="все">формат</option>
              {formatFilters.map((filter,i) => {
                return(<option key={i}>{filter}</option>)
              })}
            </select>
          </p>
          <p>
            <select onChange={filterByPlatform} ref={platformSelectRef}>
              <option selected value="все">локация</option>
              {platformFilters.map((filter,i) => {
                return(<option key={i}>{filter}</option>)
              })}
            </select>
          </p>
        </div>
      </div>
      {window.innerWidth >= 768 && data.length > 1 ? (
        <div className="columns">
          <div>
            <div className="table">
              <div className="row header">
                <div className="cell number">#</div>
                <div className="cell name">участник</div>
                <div className="cell date">сб</div>
                <div className="cell date">вс</div>
              </div>
              {data.slice(0, data.length / 2).map((item, i) => {
                return (
                  <Row item={item} key={i} i={i+1}/>
                )
              })}
            </div>
          </div>

          <div>
            <div className="table">
              <div className="row header">
                <div className="cell number">#</div>
                <div className="cell name">участник</div>
                <div className="cell date">сб</div>
                <div className="cell date">вс</div>
              </div>
              {data.slice(data.length / 2, data.length).map((item, i) => {
                return (
                  <Row item={item} key={i} i={i+1}/>
                )
              })}
            </div>
          </div>
        </div>
      ) : ('')}

      {window.innerWidth >= 768 && data.length <= 1 ? (
        <div className="columns">
          <div>
            <div className="table">
              <div className="row header">
                <div className="cell number">#</div>
                <div className="cell name">участник</div>
                <div className="cell date">сб</div>
                <div className="cell date">вс</div>
              </div>
              {data.map((item, i) => {
                return (
                  <Row item={item} key={i} i={i+1}/>
                )
              })}
            </div>
          </div>

          <div>
          </div>
        </div>
      ) : ('')}

      {window.innerWidth < 768 ? (
        <div className="columns">
          <div className="table">
            <div className="row header">
              <div className="cell number">#</div>
              <div className="cell name">участник</div>
              <div className="cell date">сб</div>
              <div className="cell date">вс</div>
            </div>
            {data.map((item, i) => {
              return (
                <Row item={item} key={i} i={i+1}/>
              )
            })}
          </div>
        </div>
      ) : ('')}
    </>
  );
}
