import React, { useState, useEffect } from "react";
import man from '../images/man.png';
import poster from '../images/poster.png';

export default function OpenCall(props) {
  return (
    <div className="openCall">
      <div className="openCallColumn">
        <div className="openCallHeader"><p>OPEN CALL</p><p>20.3–10.4.2021</p></div>
        <p><i>Art-Space-Hopping</i> снова собирает на одной карте неочевидные художественные пространства — мастерские, студии, квартиры, а также кафе, бары, книжные магазины, библиотеки и другие городские локации. Проект пройдет при поддержке Музея Москвы — и развернется в городе 24, 25 апреля.</p>
        <p>В этот раз мы расширяем формат и хотим познакомить зрителей не только с представителями художественного сообщества, но и со всем разнообразием творческих практик, которые осуществляются, производятся, существуют, наполняют, питают город и городскую среду. Мы приглашаем к участию всех, кто проявляет себя творчески в музыке, танцах, театральных постановках, перформансах, текстах и любых пограничных с искусством форматах.</p>
        <p>Мы также приглашаем кафе, бары, рестораны и другие площадки открыть свои двери для художников, артистов, поэтов и музыкантов, чтобы помочь проявиться художественной жизни города. Уверены, нас ждет много неожиданных комбинаций.</p>
        <p>Если вы куратор и вам интересно сделать с нами экскурсионную программу или вы знакомы с художниками и новыми территориями искусства и хотите рассказать о них — заполняйте форму экскурсии. Мы с радостью включим классные маршруты и экскурсии в программу <i>A-S-H</i>, как бесплатные так и платные.</p>
        <p>В этот раз мы делаем взнос за участие в событии — 1500 р. Для любых форм не предполагающих покупку/продажу — участие бесплатно. Мы также планируем сделать карту стрит-арта, но об этом попозже.То, что сделает проект возможным — это система билетов-донатов для гостей, которая позволит проекту развиваться, а также оплатить печатные материалы и работу команды. Нам важно, чтобы горожане были в контакте с локальной художественной средой и поддерживали ее, это необходимо для свободного развития искусства.</p>
        <p className="link"><br/><a href="https://forms.gle/9nnfzAE8wmtyswjQ8" target="_blank">–> ЗАЯВКА ДЛЯ УЧАСТНИКОВ</a></p>
        <p className="link"><br/><a href="https://forms.gle/SZWfDea4eF8h7x8N8" target="_blank">–> ЗАЯВКА ДЛЯ ПЛОЩАДОК</a></p>
        <p className="link"><br/><a href="https://forms.gle/U3FsRLYsWTf9maDT8" target="_blank">–> ЗАЯВКА ДЛЯ ЭКСКУРСИЙ</a></p>
        <div className="logo"/>
      </div>
      <div className="openCallColumn">
        <div className="openCallImage">
          <img src={poster}/>
          <p>постер <i>A-S-H</i>, 2020</p>
        </div>
        <div className="openCallImage">
          <img src={man}/>
          <p>человек с постером <i>A-S-H</i>, 2020</p>
        </div>
      </div>
    </div>
  );
}
