import React, { useState, useEffect } from "react";

export default function Row(props) {
  const [open, setOpen] = useState(false);

  let style = {
    backgroundImage: `url(${props.item.image})`
  }

  return (
    <>
      <div className={open ? "row open" : "row"} onClick={() => setOpen(!open)}>
        <div className="cell number">{props.item.number}.</div>
        <div className="cell name">{props.item.name}</div>
        <div className={props.item.saturday == "-" || props.item.saturday == "" ? "cell sat empty" : "cell sat"}></div>
        <div className={props.item.sunday == "-" || props.item.sunday == "" ? "cell sun empty" : "cell sun"}></div>
      </div>

      {open ? (
        <div className="descriptionRow">
          {props.item.image ? (<div className="image" style={style}/>) : ('')}

          <p className="description">{props.item.description}</p>
          {props.item.members ? (<p className="members"><span>Участники проекта: {props.item.members}<br/></span></p>) : ('')}

          {props.item.events ? (<p className="events">Программа:<br/>{props.item.events}</p>) : ('')}

          <p className="techInfo">
            {props.item.saturday || props.item.sunday ? (<span>Время: {props.item.saturday && props.item.saturday != "-" ? `сб ${props.item.saturday}` : ""}{props.item.saturday && props.item.saturday != "-" && props.item.sunday && props.item.sunday != "-" ? " / " : ""}{props.item.sunday && props.item.sunday != "-" ? `вс ${props.item.sunday}` : ""}<br/></span>) : ('')}
            {props.item.formats ? (<span>Формат: {props.item.formats}<br/></span>) : ("")}
            {props.item.platform ? (<span>Локация: {props.item.location}<br/></span>) : ('')}
            {props.item.address ? (<span>Адрес: {props.item.address}<br/></span>) : ('')}
          </p>

          {props.item.instagram || props.item.website ? (
            <p className="socialMedia">
              {props.item.instagram ? (<a href={props.item.instagram} target="_blank">Инстаграм</a>) : ('')}
              {props.item.instagram && props.item.website ? (<a href={props.item.website} target="_blank">, сайт</a>) : ('')}
              {!props.item.instagram && props.item.website ? (<a href={props.item.website} target="_blank">Cайт</a>) : ('')}
              <br/>
              {props.item.sell ? (<a href={props.item.sell} target="_blank">Онлайн-продажа работ</a>) : ('')}
            </p>) : ('')}

          <p className="register">
            {props.item.registration ? (<a href={props.item.registration} target="_blank">Регистрация <br/></a>) : ('')}
          </p>
        </div>
      ) : ("")}
    </>
  )
}

// {data.map((item, i) => {
//   return (
//     <>
//       <div className="row">
//         <div className="cell">{i++}.</div>
//         <div className="cell" id="name">{item.name}</div>
//         <div className="cell">{item.saturday}</div>
//         <div className="cell">{item.sunday}</div>
//         <div className="cell">{item.location}</div>
//       </div>
//       {openItems.includes(i) ? (<div>open</div>) : ("")}
//     </>
//   )
// })}

// <tr className="row" onClick={() => openItems.push(i)}>
//   <td className="cell">{i++}.</td>
//   <td className="cell" id="name">{item.name}</td>
//   <td className="cell">{item.saturday}</td>
//   <td className="cell">{item.sunday}</td>
//   <td className="cell">{item.location}</td>
//   {openItems.includes(i) ? (
//     <div className="description">
//       {item.description}
//     </div>
//   ) : ("")}
// </tr>
