import React, { useState, useEffect } from "react";

export default function Tickets(props) {
  useEffect(() => {
    window.scrollTo({
      top: 0
    })
  }, [])
  
  return(
    <div className="ticketsPage">
      <p>
        Проект инициирован одним из&nbsp;организаторов принт-маркета &laquo;Вкус бумаги&raquo; и&nbsp;является низовой инициативой. Команда Art-Space-Hopping (*кураторы и&nbsp;организаторы проекта, разработчики сайта, дизайнеры) работают над проектом вкладывая опыт, время, внимание и&nbsp;ресурсы для того, чтобы многие художники, в&nbsp;том числе начинающие, заявили о&nbsp;себе и&nbsp;стали видимы на&nbsp;одной&nbsp;карте.
        <br/><br/>
        Мы&nbsp;выбрали символическую стоимость билета в&nbsp;200 рублей за&nbsp;вход на&nbsp;все площадки, на&nbsp;два дня события, в&nbsp;том числе на&nbsp;мастер-классы и&nbsp;экскурсии. Мы&nbsp;будем рады если вы&nbsp;поддержите проект, купив билет, это позволит проекту развиваться.
        <br/><br/>
        Берегите себя и&nbsp;обязательно используйте средства индивидуальной защиты.<br/>
        Помните, что при посещении локаций важно оставаться в&nbsp;масках и&nbsp;перчатках. Регистрируйтесь на&nbsp;события, мастер-классы и&nbsp;мероприятия, чтобы избежать большого количества людей на&nbsp;площадке.
        <br/><br/>
        <a href="https://art-space-hopping.timepad.ru/event/1502071/" target="_blank">Купить билет</a>
        <br/><br/>
        По всем вопросом пишите: <a href="mailto:artspacehopping@gmail.com" target="_blank">artspacehopping@gmail.com</a>
      </p>
    </div>
  )
}
